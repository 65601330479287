:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --black: #000000;
    --black-900: rgba(0, 0, 0, 0.9);
    --black-800: rgba(0, 0, 0, 0.8);
    --black-700: rgba(0, 0, 0, 0.7);
    --black-600: rgba(0, 0, 0, 0.6);
    --black-500: rgba(0, 0, 0, 0.5);
    --black-400: rgba(0, 0, 0, 0.4);
    --black-300: rgba(0, 0, 0, 0.3);
    --black-200: rgba(0, 0, 0, 0.2);
    --black-100: rgba(0, 0, 0, 0.1);
    --black-50: rgba(30, 30, 30, 1);
    
    --light-s-gray: #2e2e2e;
    --input-gray:#808080;
    --light-blue:#000000;
    --light-blue-hover:#0080c8;
    --red:#FF0000;
    --border-gray:#B3B3B3;
    --light-black:#282828;

}