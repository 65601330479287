/**sidebar css Start*/

.sidebar {
    position: fixed;
    top: 0;
    left: -260px;
    width: 260px;
    height: 100%;
    background-color: var(--black);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    /* padding: 20px 0; */
}

.sidebar.open {
    left: 0;
}

.sidebar-header .logo a img {
    width: 110px;
}

.sidebar .sidebar-menu li a svg {
    width: 20px;
    height: 20px;
}

.sidebar .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid var(--white-300);
    /* padding-bottom: 20px; */
    padding: 15px 15px 15px;
    position: sticky;
    top: 0;
    background: #000;
}

.sidebar .sidebar-menu {
    list-style: none;
    padding: 10px 10px;
    margin-top: 0;
    height: 85vh;
    overflow-y: auto;
}



.sidebar .sidebar-menu li {
    margin-bottom: 0;

}

.sidebar .sidebar-menu a {
    text-decoration: none;
    color: var(--white);
    font-size: 16px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s ease-in-out;
    padding: 10px 15px;
}

.sidebar .sidebar-menu a:hover {
    color: var(--white);
    background: var(--white-200);
    border-radius: 0;
}               

.sidebar .sidebar-menu li.active a {
    color: var(--white);
    background: var(--white-200);
    border-radius: 0;
}

.sidebar .dropdown-menu {
    list-style: none;
    padding: 0;
    margin: 5px 0 0 15px;
    display: block;
    position: relative;
    background: transparent;
}


ul.dropdown-menu li a {
    background: transparent !important;
    margin-bottom: 0;
}

.sidebar .sidebar-menu ul.dropdown-menu li.active a { 
    color: var(--white);
    background: var(--white-200) !important;
    border-radius: 5px;
}

ul.dropdown-menu li a:hover { 
    color: var(--white);
    background: var(--white-200) !important;
    border-radius: 5px;
}

.overlay-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 62%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 999;
}

.sidebar .close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.sidebar .close-btn svg {
    width: 30px;
    height: 30px;
    color: var(--white);
    transition: all .3s ease-in-out;
    background: var(--light-black);
    border-radius: 50px;
    padding: 5px;
}

.sidebar .close-btn:hover svg {
    transform: rotate(360deg); 
}
  
  .sidebar-menu::-webkit-scrollbar {
    width: 4px; /* Set width of scrollbar */
  }
  
  .sidebar-menu::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); 
    border-radius: 4px; 
  }
  
  .sidebar-menu::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); 
  }

  ul.sidebar-menu.scrollable {
    overflow-y: auto;
    height:85vh;
}



