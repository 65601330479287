/* --- Web Loader Css --- */
.web-preloader {
    background-color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
}

.web-preloader span.web-inner {
    width: 95px;
    height: 95px;
    line-height: 120px;
    margin: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.web-preloader span.web-inner img {
    width: 70px;
    height: 70px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    position: relative;
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s 0.5s ease-in-out;
    -o-transition: all 0.3s 0.5s ease-in-out;
    -moz-transition: all 0.3s 0.5s ease-in-out;
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s 1s ease-in-out;
    -o-transition: all 0.3s 1s ease-in-out;
    -moz-transition: all 0.3s 1s ease-in-out;
    transition: all 0.3s 1s ease-in-out;
}