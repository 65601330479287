.main-header {
  /* background-color: var(--black);
    box-shadow: 0 0 10px var(--black-200); */
  left: 0;
  /* padding: 0 20px; */
  position: -webkit-sticky;
  /* position: sticky;
    top: 0; */
  width: 100%;
  z-index: 125
}

.mh-inner {
  height: 100px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border-bottom: 1px solid rgb(224, 224, 224);
  background: #fff;
  transition: all .3s ease-in-out;
}

.mh-inner .mh-left,
.mh-inner .mh-center,
.mh-inner .mh-right {
  flex: 1;
}

.mh-inner .mh-center {
  display: flex;
  justify-content: center;
}

.mh-inner .mh-center a img {
  width: 220px;
  object-fit: contain;
  transition: width 0.4s ease-in-out;
}

.mh-inner .mh-right {
  display: flex;
  justify-content: end;
}

.menubox-icon {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex: 0 0 40px;
  flex-direction: column;
  height: 40px;
  justify-content: space-around;
  padding: 10px;
  width: 40px;
  /* background: var(--light-black); */
}

.menubox-icon span {
  background-color: var(--black);
  border-radius: 100px;
  display: block;
  height: 2px;
  transition: .3s ease-in-out;
  width: 100%;
}

.zoom-animation {
  animation: zoomInOut 5s infinite ease-in-out;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/*-----search box start-------*/

.mh-search-bar {
  /* background-color: var(--white);
    border: 1px solid var(--white); */
  border-radius: 50px;
  color: var(--black);
  display: flex;
  /* padding: 6px 20px; */
  max-width: 227px;
  align-items: center;
}

.mh-search-bar .icon {
  color: var(--black-900);
  /* color: var(--white); */
  /* margin-right: 10px; */
  padding: 5px;
  cursor: pointer;
}

.mh-search-bar .icon svg {
  width: 24px;
  height: 24px;
}


.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.search-modal .modal-content {
  background-color: var(--black);
  padding: 30px 50px;
  border-radius: 5px;
  width: 100%;
  max-width: 650px;
  margin: 0 15px;
  position: relative;
  animation: zoomIn 0.3s ease-in-out;
}

/* Zoom-in animation */
@keyframes zoomIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Close button */
.search-modal .close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-weight: bold;
  cursor: pointer;
  color: var(--white);
  width: 30px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.search-modal .close:hover {
  transform: rotate(180deg);
}

/* Input field styling */
.search-modal .search-input {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  background: var(--black);
  border: none;
  border-bottom: 2px solid var(--light-s-gray);
  color: var(--white);
  text-align: center;
  transition: .3s ease-in-out;
}

.search-modal .search-input:focus {
  outline: none;
}

.search-modal .modal-content:hover .search-input {
  border-bottom: 2px solid var(--white);
  color: var(--white);
}

.search-modal .modal-content:hover .search-pointer {
  color: var(--white);
}

.search-pointer {
  color: var(--light-s-gray);
  width: 24px;
  position: absolute;
  right: 50px;
  top: auto;
  bottom: 50px;
  cursor: pointer;
  transition: .3s ease-in-out;
}

.mhr-inner {
  display: flex;
  gap: 10px;
}

/*-----search box End-------*/

/***catehoary header start***/

.category-header .categoary_section {
  padding: 4px 0;
  /* background: #e5e7eb73;     */
}

.category-header .categoary_section ul {
  padding-left: 0;
  display: flex;
  margin-bottom: 0;
  gap: 8px;
  font-weight: 600;
  justify-content: center;
}

.category-header .categoary_section ul li a {
  padding: 10px 8px;
}

.category-header {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px; */
  /* position: sticky;
    top: 80px; */
  background: var(--white);
  z-index: 99;
  /* margin-top: 100px; */
}

/***catehoary header End***/

@media only screen and (max-width: 767px) {

  /*-----header start-------*/

  .mh-inner .mh-left,
  .mh-inner .mh-center,
  .mh-inner .mh-right {
    flex: inherit;
  }

  /* .main-header {
        padding: 0 15px;
    } */

  .mh-inner {
    height: 70px;
  }

  .mh-inner .mh-center a img {
    width: 140px;
    transition: width 0.4s ease-in-out;
  }

  /*---header categoary css start---*/

  .category-header .categoary_section ul {
    justify-content: start;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding: 0 15px;
    gap: 15px;
  }

  .category-header .categoary_section ul li a {
    font-size: 13px;
    padding: 12px 0;
  }

  .category-header .categoary_section {
    padding: 0px 0;
  }

  /* .category-header {
    margin-top: 70px;
  } */

  .mh-inner {
    padding: 0 10px;
  }

  /*---header categoary css End---*/

  news-messsanory .card-content h3 {
    font-size: 16px;
  }  

}

@media (min-width: 768px) and (max-width: 1200px) {

  /* .main-header {
        padding: 0 15px;
    } */

  .category-header .categoary_section ul li a {
    padding: 10px 0;
  }

  .category-header .categoary_section ul {
    gap: 15px;
  }
}

/***sticky header css***/

/* Main Header - Default */
.main-header {
  transition: height 0.4s ease, background-color 0.4s ease, position 0.4s ease;
  /* Smooth transition for height, background color, and position */
  position: sticky;
    top: 0;
}

/* Shrinking Header on Scroll */

/* Logo Default Size */
/* .main-header .mh-center img {
  width: 180px;
  transition: width 0.4s ease-in-out;
} */

/* Smaller Logo when Sticky */
.main-header .mh-center img.small-logo {
  width: 120px;
  transition: width 0.4s ease-in-out;
}

/* Sticky Navigation */
.main-nevigation.sticky .main-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  transition: position 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

.main-nevigation {
  transition: all 0.4s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  position: sticky;
  top: 0;
  z-index: 9;
  margin-top: 1px;
}

.main-nevigation.sticky .mh-inner {
  height: 64px;
  transition: height 0.4s ease-in-out;
}