@media only screen and (max-width: 767px) {

    /**common css***/

    .devider {
        margin: 20px 0;
    }

    .sp-s-4 {
        padding-left: 12px;
    }
    
    .sp-e-4 {
        padding-right: 12px;
    }

    .padding-50 {
        padding: 25px 0;
    }

    .padding-top-50 {
        padding-top: 25px;
    }

    .padding-bottom-50 {
        padding-bottom: 25px;
    }

    .margin-50 {
        margin: 25px 0;
    }

    .margin-top-50 {
        margin-top: 25px;
    }

    .margin-bottom-50 {
        margin-bottom: 25px;
    }

    .icon svg {
        width: 20px;
        height: 20px;
    }

    .row-gap {
     gap: 20px;  
    } 

    .border-right {
        border-right: none;
    } 

    /**common css end***/
    
    .adver-tisement .adt-inner {
        width: 100%;
        height: 100%;
    }

    /*custom heading*/
    .custom-heading {
        font-size: 20px;
        line-height: 24px;
    }
    .custom-heading.ch-medium {
        font-size: 18px;
    }
    .custom-heading.ch-small {
        font-size: 16px;
    }
    .custom-text {
        font-size: 16px;
    }

    /*Breaking New start*/

    .breakingnews-section .brk-slider {
        width: 100%;
    }
    
    .breakingnews-section .brk-slider .news-card {
        padding: 0 8px;
        gap: 15px;
    }

    .breakingnews-section .brk-slider .news-content {
        gap: 10px;
    }

    .breakingnews-section .brklist-trading .custom-heading.ch-medium {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    .politicsnew-section .pls-inner img {
        width: 100%;
        height: 100%;
    }

    .breakingnews-section .brk-slider .news-image {
        width: 100%;
        height: 100%;
    }

    /*Breaking New End*/

     /*Common infromation start*/
    .common-infromation {
        padding: 8px 8px;
        box-shadow: 0 0 2px var(--black-200);
    }

    .common-infromation .cni-inner .cni-datetime {
        font-size: 12px;
        gap: 5px;
    }

    .common-infromation .cni-detail {
        gap: 10px;
    }

    .common-infromation .cni-inner .cni-datetime span img {
        width: 25px;
    }

    .common-infromation .cni-detail svg {
        width: 20px;
        height: 20px;
    }

     /*Common infromation start*/

     /*Politics New start*/
     .politicsnew-section .pls-inner {
        gap: 15px;
     }
     /*Politics New End*/

     /*commoninformation css start*/
     .common-news-box .cnb-box .cnb-content .custom-heading.ch-medium {
        width: 100%;
        font-size: 16px;
     }

     /*commoninformation css End*/

     /*card slider css start*/
     .common-news-slider {
        margin: 25px 0;
        padding: 50px 0;
     } 

     .common-news-slider .cnms-slider .slide img{
        width: 100%;
        height: 100%;
     }

     .common-news-slider .cnms-slider .slick-slide {
        margin: 0 8px;
    }

     /*sportnews css start*/
     .sport-news-section .common-news-box .cnb-box.latest-news .cnb-inner img {
        width: 100%;
        height: 100%;
     }

     .sport-news-section .spns-inner img {
        width: 100%;
        height: 100%;
    }

     /*sportnews css End*/

     /*month story css start*/
     .month-story {
        padding: 50px 0;
     }

     .month-story .ms-inner .custom-heading {
        font-size: 24px;
        line-height: 30px;
        text-align: left;
     }

     .month-story .ms-inner .custom-text {
        font-size: 14px;
        height: 100%;
        line-height: 22px;
    }

    /*Topstoryslider css start*/
    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .top-story-section .tps-inner .common-news-box {
        padding: 0 10px 0;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img {
        flex: 0 0 100%;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img img {
        height: 100%;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-content {
        gap: 8px;
        width: 100%;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-content .custom-text {
        font-size: 14px;
    }

    /*Topstory slider cssEnd*/x

    /*Market section css start*/
    .market-section .mrs-inner {
        padding: 15px 0;
    }

    .market-section .mrs-img img {
        width: 100%;
        height: 100%;
        /* height: 260px; */
    }    

    .market-section .mrs-detail .mrs-img .custom-heading{
        font-size: 20px;
    }

    .market-section .mrs-detail .ps-4 {
        padding-left: 24px;
    }

    /*Market section css End*/


    /***contactus css start***/
    .contact-box .contact-us-container {
        flex-direction: column;
    }

    /***link css***/

    .active .link:before {
        bottom: 0;
    }

    /****SiteMap css Start***/
    .sitemap-ul li {
        margin: 5px 0;
        padding: 0px 8px;
    }

    .sitemap-ul li a {
        font-size: 14px;
    }

    .sitemap-link-box {
        margin-bottom: 25px;
        /* padding: 10px 10px 10px 10px; */
    }

    .sitemap-section .flex-box {
        padding: 10px 8px;
    }
    /****SiteMap css End***/

    /***social sharing css Start***/
    .social-shearing ul {
        justify-content: center;
        gap: 8px;
    }

    .news-messsanory .main-content h2 {
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .news-messsanory .main-content h3 {
        -webkit-line-clamp: 2;
        font-size: 14px;
    }

    .news-messsanory .main-content {
        padding: 15px;
    }
    .news-messsanory .main-content p {
        margin-bottom: 0;
    }

     /***social sharing css end***/


    .flex-box {
        padding: 10px 0;
    }

    .breakingnews-section .brk-left .custom-heading.ch-medium {
        padding: 10px 0;
    }

    .category-section .flex-box {
        padding-bottom: 25px;
    }

    /***new detail page***/
    
    .main-detail-section {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    .mds-right-inner.main-artical .title {
        font-size: 24px;
        padding: 10px;
    }

    .main-detail-section .mds-left-inner {
        order: 2;
    }

    .main-detail-section .mds-right-inner.main-artical {
        order: 1;
    }

    .main-detail-section .mds-left-inner img {
        height: auto;
        width: 100%;
        object-fit: contain;
    }

    .social-media-post { 
        /* flex-wrap: wrap; */
        gap: 10px;
    }

    .news-timestamp  {
        font-size: 14px;
    }
    /***new design***/

    .news-container {
        grid-template-columns: repeat(1, 1fr);
        gap: inherit;
    }

    .news-card.main-card {
        grid-template-columns: repeat(1, 1fr);
    }

    .news-container-section {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .news-container-section a.news-card img {
        width: 100%;
        height: auto;
    }

    .news-title {
        font-size: 16px;
    }

    .news-card.main-card .left-mainnews-inner {
        order: 2;
    }

    .right-mainnews-inner {
        width: 100%;
        height: 100%;
    }

    .main-card .news-title {
        font-size: 20px;
    }

    /***cover stories***/
    .coverstories .custom-heading {
        font-size: 30px;
        padding: 0px 0;
        line-height: 35px;
    }

    /* .detail-page .dp-inner {
        min-height: auto;
    } */

}


@media (min-width: 768px) and (max-width: 1200px) { 

    /**common css***/

    .padding-50 {
        padding: 25px 0;
    }

    .padding-top-50 {
        padding-top: 25px;
    }

    .padding-bottom-50 {
        padding-bottom: 25px;
    }

    .margin-50 {
        margin: 25px 0;
    }

    .margin-top-50 {
        margin-top: 25px;
    }

    .margin-bottom-50 {
        margin-bottom: 25px;
    }

    .icon svg {
        width: 20px;
        height: 20px;
    }

    .row-gap {
     gap: 20px;  
    } 

    /**common css end***/
    
    .adver-tisement .adt-inner {
        width: 100%;
        height: 100%;
    }

    /*custom heading*/
    .custom-heading {
        font-size: 20px;
        line-height: 24px;
    }
    .custom-heading.ch-medium {
        font-size: 18px;
    }
    .custom-text {
        font-size: 16px;
    }

    /*Breaking New start*/
    
    .breakingnews-section .brk-slider .news-card {
        padding: 0 8px;
        gap: 15px;
    }

    .breakingnews-section .brk-slider .news-content {
        gap: 10px;
    }

    .breakingnews-section .brklist-trading .custom-heading.ch-medium {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    .politicsnew-section .pls-inner img {
        width: 100%;
        height: 100%;
    }

    .breakingnews-section .brk-slider .news-image {
        width: 100%;
        height: 100%;
    }

    /*Breaking New End*/

     /*Common infromation start*/
    .common-infromation {
        padding: 8px 15px;
        box-shadow: 0 0 2px var(--black-200);
        /* position: sticky;
        top: 80px;  */
        background:var(--white);
        z-index: 99;
    }
    
    .common-infromation .cni-inner .cni-datetime {
        font-size: 12px;
        gap: 5px;
    }

    .common-infromation .cni-detail {
        gap: 15px;
    }

    .common-infromation .cni-inner .cni-datetime span img {
        width: 25px;
    }

    .common-infromation .cni-detail svg {
        width: 20px;
        height: 20px;
    }

     /*Common infromation start*/

     /*Politics New start*/
     .politicsnew-section .pls-inner {
        gap: 15px;
     }
     /*Politics New End*/

     /*commoninformation css start*/
     .common-news-box .cnb-box .cnb-content .custom-heading.ch-medium {
        width: 100%;
        font-size: 16px;
     }

     /*commoninformation css End*/

     /*card slider css start*/
     .common-news-slider {
        margin: 25px 0;
        padding: 50px 0;
     } 

     .common-news-slider .cnms-slider .slide img{
        width: 100%;
        height: 100%;
     }

     .common-news-slider .cnms-slider .slick-slide {
        margin: 0 8px;
    }

     /*sportnews css start*/
     .sport-news-section .common-news-box .cnb-box.latest-news .cnb-inner img {
        width: 100%;
        height: 100%;
     }

     .sport-news-section .common-news-box.row-gap {
        gap: 20px;
    }

     /*sportnews css End*/

     /*month story css start*/
     .month-story {
        padding: 50px 0;
     }

     .month-story .ms-inner .custom-heading {
        font-size: 54px;
        line-height: 80px;
     }

     .month-story .ms-inner .custom-text {
        font-size: 18px;
        height: 100%;
    }

    .month-story .ms-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /*Topstoryslider css start*/
    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner {
        display: flex;
        /* flex-direction: column; */
        gap: 15px;
    }

    .top-story-section .tps-inner .common-news-box {
        padding: 0 10px 0;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img {
        flex: 0 0 210px;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img img {
        height: 100%;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-content {
        gap: 8px;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-content .custom-text {
        font-size: 14px;
    }

    /*Topstory slider cssEnd*/

    /*Market section css start*/
    /* .market-section .mrs-inner {
        padding: 15px 0;
    } */

    .market-section .mrs-img img {
        width: 100%;
        /* height: 260px; */
        height: 100%;
    }    

    .market-section .mrs-detail .mrs-img .custom-heading{
        font-size: 20px;
    }

    .market-section .mrs-detail .ps-4 {
        padding-left: 24px;
    }
    .market-section .mrs-detail .common-news-box.upcoming-news .cnb-box .cnb-inner .cnb-img {
        flex: 0 0 auto;
    }

    /*Market section css End*/

    /***link css Start***/

    .common-infromation .cni-inner .categoary_section ul {
        gap:8px;
    }


    /***link css End***/

    .news-messsanory .main-content h2 {
        font-size: 18px;
    }

    
    .flex-box {
        padding: 10px 2px;
    }

    .breakingnews-section .brk-left .custom-heading.ch-medium {
        padding: 10px 1px;
    }

    .category-section .flex-box {
        padding-bottom: 25px;
    }

    .mds-right-inner.main-artical .title {
        font-size: 30px;
        padding: 1rem 10px;
        font-weight: 600;
    }

     /***new design***/

    .news-container-section {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .news-container-section a.news-card img {
        width: 100%;
        height: auto;
    }

    .news-title {
        font-size: 16px;
    }

    .right-mainnews-inner {
        width: 100%;
        height: 100%;
    }

    .main-card .news-title {
        font-size: 20px;
    }

    .news-card.main-card .news-image {
        height: auto;
    }

    .main-artical .image-container {
        height: 330px;
    }
  
}

@media (min-width: 1201px) and (max-width: 1400px) { 
    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img img {
        height: 100%;
    }

    .top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img {
        flex: 0 0 300px;
    }

    .news-messsanory .main-content h2 {
        font-size: 24px; 
    } 
    
    .main-artical .image-container {
        height: 330px;
    }
}

